<script setup>
import { useInfoCargueFacturasStore } from '../../../store/infoCargueFacturas.store'
import { storeToRefs } from 'pinia'
import { helper } from '../../../../../../../utils/helper'
import { defineOptions } from 'vue'
import Icon from '@/components/Icon/index.vue'

defineOptions({
  name: 'CardInfoPedidos'
})

const infoStore = useInfoCargueFacturasStore()
const { numPedidosConsultados, infoPedidos } = storeToRefs(infoStore)
</script>

<template>
  <Card
    :pt="{
      content: { style: 'padding: 0.5rem 0' }
    }"
  >
    <template #title>
      <div class="flex flex-wrap items-end">
        <span class="text-2xl mr-2">PEDIDO(S) #</span>
        <span class="text-2xl font-normal underline text-gray-700">{{ numPedidosConsultados }}</span>
      </div>
    </template>
    <template #content>
      <div class="flex justify-between pb-4">
        <div>
          <div style="font-size: smaller;">
            <strong>Nombre Proveedor: </strong>{{ infoPedidos[0].CardName }}
          </div>
          <div style="font-size: smaller;">
            <strong>Fecha Pedido: </strong>{{ infoPedidos[0].DocDate }}
          </div>
          <div style="font-size: smaller;">
            <strong>Items en los pedidos: </strong>{{ infoPedidos.length }}
          </div>
        </div>
      </div>

      <DataTable
        :value="infoPedidos"
        class="p-datatable-sm text-xs custom-scrollbar"
        showGridlines
        dataKey="Index"
        responsiveLayout="scroll"
        scrollHeight="750px"
        :scrollable="true"
        ref="tableList"
        removableSort
        :pt="{
          thead: { style: 'top: -1px' }
        }"
      >
        <template #empty>
          No se encontraron datos registrados.
        </template>
        <Column
          field="ItemCode"
          header="Descripción Artículo"
          style="max-width:28rem"
        >
          <template #body="{ data }">
            <div class="flex flex-col" :id="`ped_id_${data.DocNum}_${data.LineNum}`" :class="{ highlight: `ped_id_${data.DocNum}_${data.LineNum}` === highlightedRowId }">
              <div class="flex flex-wrap justify-between">
                <span class="custom-ellipsis"><strong>Código Mx: </strong>{{ data.ItemCode }}</span>
                <div class="flex flex-wrap">
                  <Badge
                    :value="`#${data.Index}`"
                    style="height: 0.9rem; font-size: 0.6rem; line-height: 0.9rem;"
                    class="bg-gray-300 text-gray-900 capitalize cursor-pointer font-bold"
                  />
                  <Badge
                    :value="`Pedido: ${data.DocNum} - Linea: ${data.LineNum}`"
                    style="height: 0.9rem; font-size: 0.6rem; line-height: 0.9rem;"
                    class="bg-blue-100 text-blue-900 capitalize cursor-pointer font-bold"
                    v-tippy="{ content: `
                      <div class='flex flex-col'>
                        <span>Fecha Pedido: ${data.DocDate}</span>
                        <span>Comentarios: ${![null, undefined].includes(data.Comments) ? data.Comments : ''}</span>
                        <span>Total Pedido: ${$h.formatCurrency(data.DocTotal)}</span>
                      </div>
                    `, trigger: 'click' }"
                  />
                </div>
              </div>
              <span
                class="custom-ellipsis"
                v-tippy="{ content: `
                  ${data.Dscription}
                `, trigger: 'click' }"
              >
                <strong>Nombre: </strong>{{ data.Dscription }}
              </span>
              <span class="custom-ellipsis"><strong>Laboratorio: </strong>{{ data.U_PHR_Lab }}</span>
              <span class="custom-ellipsis"><strong>Presentación: </strong>{{ data.U_PHR_Presentacion }}</span>
              <div class="flex flex-wrap justify-between">
                <!-- <pre>{{ JSON.stringify(data.InfoItem, null, '\t') }}</pre> -->
                <div class="flex flex-wrap">
                  <span class="font-bold mr-1">Cod. Cum:</span>
                  <span class="border-b border-dashed">
                    {{ data.Cum }}
                  </span>
                  <Icon
                    icon="mdi:content-copy"
                    class="ml-1 text-black cursor-pointer"
                    v-tippy="{ content: 'Copiar CUM al portapapeles' }"
                    @click="copyToClipboard(data.Cum)"
                  />
                  <!-- Mostrar un texto de Copiado y ocultarlo despues de 1 seg -->
                  <span
                    v-if="copiedCum === data.Cum"
                    class="text-xs text-green-500 ml-1"
                  >
                    Copiado
                  </span>
                </div>
                <Badge
                  v-if="data.isInPedido"
                  @click="scrollToRowFactura(data)"
                  :value="`En factura`"
                  style="height: 0.9rem; font-size: 0.6rem; line-height: 0.9rem;"
                  class="bg-green-100 text-green-900 capitalize cursor-pointer font-bold"
                />
              </div>
              <div class="flex justify-between">
                <span
                  class="custom-ellipsis"
                  v-tippy="{ content: `
                    ${data.Agrupacion}
                  `, trigger: 'click' }"
                >
                  <strong>Agrupación: </strong>{{ data.Agrupacion }}
                </span>
                <Badge
                  v-if="data.IdAgrupacion"
                  :value="`Id Agrup: ${data.IdAgrupacion}`"
                  style="height: 0.9rem; font-size: 0.6rem; line-height: 0.9rem;"
                  class="whitespace-nowrap bg-gray-300 text-gray-900 capitalize cursor-pointer font-bold"
                />
              </div>
              <span class="custom-ellipsis"><strong>Bodega: </strong>{{ data.bodega }}</span>
            </div>
          </template>
        </Column>
        <Column
          field="Quantity"
          header="Valores"
          style="width:11rem; min-width:11rem"
        >
          <template #body="{ data }">
            <div class="flex-col w-full" :id="`ped_id_${data.DocNum}_${data.ItemCode}_${data.LineNum}`" :class="{ highlight: `ped_id_${data.DocNum}_${data.ItemCode}_${data.LineNum}` === highlightedRowId }">
              <div class="flex flex-wrap justify-between items-center mb-1 w-full">
                <span class="font-bold">Cant. Total:</span>
                <span class="text-blue-700">{{ parseInt(data.Quantity) }}</span>
              </div>
              <div class="flex flex-wrap justify-between items-center mb-1 w-full">
                <span class="font-bold">Cant. Pendiente:</span>
                <span class="text-red-700">{{ parseInt(data.OpenQty) }}</span>
              </div>
              <div class="flex flex-wrap justify-between items-center mb-1 w-full">
                <span class="font-bold">Valor Unitario:</span>
                <span>{{ helper.formatCurrency(parseInt(data.Price)) }}</span>
              </div>
              <div class="flex flex-wrap justify-between items-center mb-1 w-full">
                <span class="font-bold">Valor Total:</span>
                <span>{{ helper.formatCurrency(parseInt(data.LineTotal)) }}</span>
              </div>
            </div>
          </template>
        </Column>
      </DataTable>
    </template>
  </Card>
</template>

<style scoped>
::v-deep(.p-column-header-content) {
  justify-content: center;
}

.floating-button {
  position: fixed !important;
  bottom: 35px;
  right: 40px;
  z-index: 1050;
}

.custom-scrollbar ::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

.custom-scrollbar ::-webkit-scrollbar-thumb {
  background-color: #c0c0c0;
  border-radius: 5px;
}

.custom-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@keyframes highlight {
  0% {background-color: #ffff99;}
  100% {background-color: transparent;}
}

.highlight {
  animation-name: highlight;
  animation-duration: 3s;
}

.highlight_pedido {
  background-color: #99ff9b;
}

@keyframes highlight_copy {
  0% {background-color: #5fd0aa;}
  100% {background-color: transparent;}
}

.highlight_copy {
  animation-name: highlight_copy;
  animation-duration: 3s;
}
</style>
