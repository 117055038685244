import { defineStore } from 'pinia'
import Swal from 'sweetalert2'
import RecepcionPedidosService from '@/apps/pharmasan/compras/logistica/services/rcp-pedidos.service'

const _RecepcionPedidosService = new RecepcionPedidosService()

export const useInfoCargueFacturasStore = defineStore({
  id: 'infoCargueFacturasStore',
  state: () => ({
    infoPreliminar: {},
    selectedItems: [],
    infoPedidos: [],
    numPedidos: [],
    hidePedido: true,
    disabledButtonPedido: true,
    proveedorNoCum: false,
    numPedidosConsultados: ''
  }),
  getters: {
    _getInfoPreliminar: (state) => state.infoPreliminar,
    _getSelectedItems: (state) => state.selectedItems,
    _getInfoPedidos: (state) => state.infoPedidos,
    _getNumPedidos: (state) => state.numPedidos,
    _getHidePedido: (state) => state.hidePedido,
    _getDisabledButtonPedido: (state) => state.disabledButtonPedido,
    _getProveedorNoCum: (state) => state.proveedorNoCum,
    _getNumPedidosConsultados: (state) => state.numPedidosConsultados
  },
  actions: {
    _setSelectedItems (payload) {
      this.selectedItems = payload
    },
    _setInfoPedidos (payload) {
      this.infoPedidos = payload
    },
    _setInfoPreliminar (payload) {
      this.infoPreliminar = payload
      this.numPedidos = payload.Pedidos
      console.log('Info preliminar pedidos --> ', payload.Pedidos)
    },
    _actionClearPedidos () {
      this.numPedidos = []
      this.disabledButtonPedido = true
      this.hidePedido = true
      this.infoPedidos = []
      this.proveedorNoCum = false
    },
    _actionViewPedido () {
      this.hidePedido = !this.hidePedido
    },
    async _actionSearchPedidos () {
      // Validar que al menos un pedido esté ingresado
      if (this.numPedidos.length === 0) {
        this.numPedidos = this.numPedidosConsultados.split(',')

        return Swal.fire({
          icon: 'warning',
          title: 'Advertencia',
          text: 'Debe ingresar al menos un número de pedido para buscar',
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true
        })
      }

      console.log('Info nit proveedor --> ', this.infoPreliminar.NitProveedor)

      _RecepcionPedidosService.getPedidosFactura({
        numPedidos: this.numPedidos,
        nit: this.infoPreliminar.NitProveedor
      }).then(({ data, status }) => {
        // Si viene solo pedidos que pertenecen al proveedor
        if (data.validacionInfo.pedidosPertenecen.length > 0 && data.validacionInfo.pedidosNoExistentesOcerrados.length === 0 && data.validacionInfo.pedidosNoPertenecen.length === 0) {
          Swal.fire({
            icon: 'success',
            title: 'Información',
            html: 'Ok',
            showConfirmButton: true,
            allowOutsideClick: false
          }).then(async (result) => {
            if (result.isConfirmed) {
              this.disabledButtonPedido = false
              this.hidePedido = false
              this.infoPedidos = data.infoData
              // Agregarle un index incremental a cada item
              this.infoPedidos.forEach((x, index) => {
                x.Index = index + 1
              })

              // Eliminar los pedidos que no pertenecen al proveedor y los que no existen o están cerrados
              this.numPedidos = data.validacionInfo.pedidosPertenecen
              this.numPedidosConsultados = data.validacionInfo.pedidosPertenecen.join(',')
            }
          })
        } else if (data.validacionInfo.pedidosPertenecen.length > 0 && (data.validacionInfo.pedidosNoExistentesOcerrados.length > 0 || data.validacionInfo.pedidosNoPertenecen.length > 0)) {
          const htmlValidacion = `
            <p class="text-sm">Se cargará la información de los siguientes pedidos:</p>
            <span class="text-green-700 font-semibold text-sm">
              ${data.validacionInfo.pedidosPertenecen.join(',')}
            </span>

            ${data.validacionInfo.pedidosNoExistentesOcerrados.length > 0
              ? ` <p class="mt-2 text-sm">Los siguientes pedidos no existen o se encuentran cerrados:</p>
              <span class="text-red-700 font-semibold text-sm">
                ${data.validacionInfo.pedidosNoExistentesOcerrados.join(',')}
              </span>`
              : ''
            }

            ${data.validacionInfo.pedidosNoPertenecen.length > 0
              ? `<p class="mt-2 text-sm">Los siguientes pedidos no pertenecen al proveedor:</p>
              <span class="text-red-700 font-semibold text-sm">
                ${data.validacionInfo.pedidosNoPertenecen.join(',')}
              </span>`
              : ''
            }
            <p class="mt-4 text-xxs text-gray-600 underline">Por favor, verifique que el número de pedido sea correcto y que el proveedor sea el mismo de la factura.</p>
          `

          Swal.fire({
            icon: 'info',
            title: 'Información',
            html: htmlValidacion,
            showConfirmButton: true,
            allowOutsideClick: false
          }).then(async (result) => {
            if (result.isConfirmed) {
              this.disabledButtonPedido = false
              this.hidePedido = false
              this.infoPedidos = data.infoData
              this.infoPedidos.forEach((x, index) => {
                x.Index = index + 1
              })
              // Eliminar los pedidos que no pertenecen al proveedor y los que no existen o están cerrados
              this.numPedidos = data.validacionInfo.pedidosPertenecen
              this.numPedidosConsultados = data.validacionInfo.pedidosPertenecen.join(',')
            }
          })
        } else if (data.validacionInfo.pedidosNoExistentesOcerrados.length > 0 && (data.validacionInfo.pedidosPertenecen.length === 0 && data.validacionInfo.pedidosNoPertenecen.length === 0)) {
          Swal.fire({
            icon: 'error',
            title: 'Validación',
            html: 'Los pedidos ingresados no existen o ya se encuentran cerrados',
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true
          })
        } else if (data.validacionInfo.pedidosNoPertenecen.length > 0 && (data.validacionInfo.pedidosPertenecen.length === 0 && data.validacionInfo.pedidosNoExistentesOcerrados.length === 0)) {
          Swal.fire({
            icon: 'error',
            title: 'Validación',
            html: 'Los pedidos ingresados no pertenecen al proveedor',
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true
          })
        }
      }).catch((res) => {
        // Limpiar pedidos
        this._actionClearPedidos()
        console.log('Error pedido data --> ', res)
      })
    }
  }
})
