<script setup>
import { defineOptions, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import SearchPedidos from '../components/searchPedidos.vue'
import CardInfoPedidos from '../components/cardInfoPedidos.vue'
import RecepcionPedidosService from '@/apps/pharmasan/compras/logistica/services/rcp-pedidos.service'
import { useInfoCargueFacturasStore } from '../../../store/infoCargueFacturas.store'
import Swal from 'sweetalert2'
import { storeToRefs } from 'pinia'
import { menuCollapse } from '@/utils/collapseMenu'
// import { useToast } from 'primevue/usetoast'

defineOptions({
  name: 'editPreliminar'
})

// const toast = useToast()
const route = useRoute()
const router = useRouter()
const infoStore = useInfoCargueFacturasStore()
const { hidePedido } = storeToRefs(infoStore)
const _RecepcionPedidosService = ref(new RecepcionPedidosService())

/**
 * Función para obtener la preliminar de entrada
 * @param id
 * @return {Promise<void>}
 */
const getPreliminarEntrada = async (id) => {
  console.log('fetchPreliminarEntrada')
  await _RecepcionPedidosService.value.getPreliminarEntrada(id).then(({ status, data: response }) => {
    if (status !== 200) return

    // Si el valor es null, 0 o undefined, mostrar un swal de error y redireccionar al listado
    if (response.length <= 0) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'La preliminar no existe'
      }).then(() => {
        router.push({ name: 'pharmasan.compras.logistica.cargue-factura.listado' })
      })
    } else {
      // Setear la preliminar en el store
      infoStore._setInfoPreliminar(response)
    }
  }).catch((error) => {
    console.error('Error getPreliminarEntrada', error)
  })
}

// Hook mounted
onMounted(async () => {
  console.log('editPreliminar mounted')
  menuCollapse(route)

  const { Id } = route.params
  await getPreliminarEntrada(Id) // Obtener la preliminar de entrada
  await infoStore._actionSearchPedidos() // Buscar pedidos
})
</script>

<template>
  <Toast />

  <Toolbar class="box mt-4 p-p-2">
    <template #start>
      <back-button />
    </template>
  </Toolbar>

  <div class="grid grid-cols-12 gap-6 mt-5">
    <div class="intro-y col-span-12 lg:col-span-12">
      <div class="intro-y box">
        <!-- Titulo Card -->
        <div class="flex flex-col sm:flex-row items-center p-4 border-b border-gray-200 dark:border-dark-5">
          <h2 class="font-medium text-lg mr-auto text-yellow-700">{{ 'Editar Cargue Factura' }}</h2>
        </div>
        <!-- Body Card -->
        <div class="p-4">
          <div class="grid grid-cols-12 gap-2">
            <!-- Componente de busqueda de pedidos -->
            <SearchPedidos />
            <!-- Información de los pedidos -->
            <div v-if="!hidePedido" :class="`${hidePedido ? '' : 'col-span-12 lg:col-span-5'}`">
              <CardInfoPedidos />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<style scoped>

</style>
