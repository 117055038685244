<script setup>
import { defineOptions, onMounted, ref } from 'vue'
import { useInfoCargueFacturasStore } from '../../../store/infoCargueFacturas.store'
import { storeToRefs } from 'pinia'

defineOptions({
  name: 'searchPedidos'
})

const infoStore = useInfoCargueFacturasStore()
const { numPedidos, hidePedido, disabledButtonPedido } = storeToRefs(infoStore)
const selectedFilterSearch = ref(1)
const listFilterSearch = ref([
  { label: 'Agrupación', value: 1 },
  { label: 'Agrupación, conversión', value: 2 },
  { label: 'Agrupación, expediente', value: 3 }
])

/* Buscar pedidos */
const searchPedido = async () => {
  console.log('Buscando pedidos')
  // Aplicar busqueda en el store
  await infoStore._actionSearchPedidos()
}

/* Limpiar pedidos */
const clearPedidos = () => {
  console.log('Limpiando pedidos')
  // Aplicar clear en el store
  infoStore._actionClearPedidos()
}

/* Mostrar u ocultar pedido */
const viewPedido = () => {
  console.log('Mostrar u ocultar pedido')
  // Aplicar clear en el store
  infoStore._actionViewPedido()
}

/* Hook onMounted */
onMounted(async () => {
  console.log('searchPedidos mounted')
})
</script>

<template>
  <!-- Busqueda de pedidos -->
  <div class="col-span-12">
    <div class="flex w-full flex-col lg:flex-row mb-2">
      <div class="flex flex-col w-full">
        <label for="pedidos" class="w-full">Número de Pedidos: <small class="text-gray-400">Digite los números de pedidos separado por comas (Ex: 111,122,...)</small></label>
        <Chips
          id="pedidos"
          v-model="numPedidos"
          separator=","
          class="w-full"
          :allowDuplicate="false"
          addOnBlur
          :pt="{
            container: { style: 'width: 100%;' },
            input: { style: 'font-size: 0.75rem' },
            inputToken: { style: 'font-size: 0.7rem; padding: 0' },
            token: { style: 'font-size: 0.75rem; padding: 0rem 0.4rem' }
          }"
        />
      </div>
      <div class="flex-grow flex flex-col w-full lg:w-1/3 lg:ml-2 lg:mr-2">
        <label for="filterSearch" class="w-full">Tipo de busqueda:</label>
        <Dropdown
          id="filterSearch"
          v-model="selectedFilterSearch"
          :options="listFilterSearch"
          optionLabel="label"
          optionValue="value"
          placeholder="Seleccione una opción"
          :filter="true"
          filterPlaceholder="Buscar..."
          class="w-full text-sm"
          :pt="{
            root: { style: 'width: 100%; height: 34px' },
            input: { style: 'font-size: 0.75rem; padding: 0.35rem 0.5rem' },
            panel: { style: 'font-size: 0.75rem' },
            item: { style: 'padding: 0.5rem 1rem' }
          }"
        />
      </div>
      <div class="flex-grow flex justify-end items-end gap-2 ml-2 mt-2 lg:mt-0">
        <Button
          icon="pi pi-search"
          severity="primary"
          class="p-button-xs"
          style="height: 32px"
          v-tippy="{ content: 'Buscar' }"
          @click="searchPedido"
        />
        <Button
          icon="pi pi-trash"
          severity="danger"
          class="p-button-xs"
          style="height: 32px"
          v-tippy="{ content: 'Limpiar' }"
          @click="clearPedidos"
        />
        <!-- Boton para mostrar y ocultar pedido -->
        <Button
          :label="hidePedido ? 'Mostrar Pedido' : 'Ocultar Pedido'"
          :icon="hidePedido ? 'pi pi-eye' : 'pi pi-eye-slash'"
          :disabled="disabledButtonPedido"
          severity="secondary"
          size="small"
          style="height: 32px; width: max-content"
          @click="viewPedido"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
